import * as Msdyn365 from '@msdyn365-commerce/core';
import * as DataServiceEntities from './DataServiceEntities.g';
import { mWPGetQuotationsByCustomerIdAsync } from './DataActionExtension.g';

/**
 * GetQuotationHistory Input Action
 */
export class GetQuotationHistoryInput implements Msdyn365.IActionInput {
    public readonly customerId: string;

    constructor(customerId: string) {
        this.customerId = customerId;
    }

    public getCacheKey = () => `QuotationHistory-${this.customerId}`;
    public getCacheObjectType = () => 'CustomerQuotations';
    public dataCacheType = (): Msdyn365.CacheType => 'application';
}

/**
 * Data model capturing the response of the action
 */
export interface IGetQuotationHistoryData {
    quotations: DataServiceEntities.IMWPQuotationsByCustIdEntity[];
}

/**
 * Creates the input required to make the action call
 */
const createInput = (args: Msdyn365.ICreateActionContext): Msdyn365.IActionInput => {
    const customerId = args.requestContext.user.customerAccountNumber;
    if (!customerId) {
        throw new Error('Customer ID is required to fetch quotation history.');
    }
    return new GetQuotationHistoryInput(customerId);
};

/**
 * Action to fetch quotation history by customer ID
 */
async function action(input: GetQuotationHistoryInput, ctx: Msdyn365.IActionContext): Promise<IGetQuotationHistoryData> {
    const customerId = input.customerId;
    if (!customerId) {
        return Promise.reject('Customer ID not provided');
    }

    // Call the async function to get quotations by customer ID
    const quotations = await mWPGetQuotationsByCustomerIdAsync({ callerContext: ctx, bypassCache: 'getAndPut' }, customerId);
    return { quotations };
}

export default Msdyn365.createObservableDataAction({
    action: <Msdyn365.IAction<IGetQuotationHistoryData>>action,
    id: 'GetQuotationHistory',
    input: createInput
});
